import { LayoutAuth, LayoutDefault, RouteWrapper } from '@/components/layouts'

export const publicRoute = [
  {
    path: '*',
    component: () => import('@/views/error/NotFound.vue'),
  },
  {
    path: '/auth',
    component: LayoutAuth,
    meta: { title: 'auth_login' },
    redirect: '/auth/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'Login',
        meta: { title: 'auth_login' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue'),
      },
      {
        path: 'logout',
        name: 'Logout',
        meta: { title: 'auth_logout' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Logout.vue'),
      },
    ],
  },

  {
    path: '/password',
    component: LayoutAuth,
    meta: { title: 'password' },
    redirect: '/password/reminder',
    hidden: true,
    children: [
      {
        path: 'reminder',
        name: 'PasswordReminder',
        meta: { title: 'password_reminder' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/password/Reminder.vue'),
      },
    ],
  },

  {
    path: '/403',
    name: 'Forbidden',
    meta: {
      title: 'error_403',
      hiddenInMenu: true,
    },
    component: () => import('@/views/error/Deny.vue'),
  },

  {
    path: '/404',
    name: '404',
    meta: {
      title: 'error_404',
    },
    component: () => import('@/views/error/NotFound.vue'),
  },

  {
    path: '/500',
    name: '500',
    meta: {
      title: 'error_500',
    },
    component: () => import('@/views/error/Error.vue'),
  },
]

export const protectedRoute = [
  {
    path: '/',
    name: 'Dashboard',
    component: LayoutDefault,
    meta: { title: 'top', icon: '' },
    redirect: '/barcode',
    children: [
      {
        path: '/barcode',
        component: RouteWrapper,
        meta: {
          title: 'barcode',
          icon: 'photo',
        },
        redirect: '/barcode/scan',
        children: [
          {
            path: '/barcode/scan',
            name: 'BarcodeScan',
            meta: {
              title: 'barcode_scan',
              icon: 'photo',
            },
            component: () => import('@/views/barcode/Scan.vue'),
          },
        ],
      },
      {
        path: '/equipment',
        component: RouteWrapper,
        meta: {
          title: 'equipment',
          icon: 'inventory',
        },
        redirect: '/equipment/index',
        children: [
          {
            path: '/equipment/index',
            name: 'EquipmentIndex',
            meta: {
              title: 'equipment_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/equipment/Index.vue'),
          },
          {
            path: '/equipment/create',
            name: 'EquipmentCreate',
            meta: {
              title: 'equipment_create',
              icon: 'add',
            },
            component: () => import('@/views/equipment/Create.vue'),
          },
          {
            path: '/equipment/upload',
            name: 'EquipmentUpload',
            meta: {
              title: 'equipment_upload',
              icon: 'upload',
            },
            component: () => import('@/views/equipment/Upload.vue'),
          },
          {
            path: '/equipment/detail/:equipment_id',
            name: 'EquipmentDetail',
            meta: {
              title: 'equipment_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/equipment/Detail.vue'),
          },
          {
            path: '/equipment/scan',
            name: 'EquipmentScan',
            meta: {
              title: 'equipment_scan',
              icon: 'photo',
            },
            component: () => import('@/views/equipment/Scan.vue'),
          },
        ],
      },
      {
        path: '/event',
        component: RouteWrapper,
        meta: {
          title: 'event',
          icon: 'event',
        },
        redirect: '/event/index',
        children: [
          {
            path: '/event/index',
            name: 'EventIndex',
            meta: {
              title: 'event_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/event/Index.vue'),
          },
          {
            path: '/event/create',
            name: 'EventCreate',
            meta: {
              title: 'event_create',
              icon: 'add',
            },
            component: () => import('@/views/event/Create.vue'),
          },
          {
            path: '/event/detail/:event_id',
            name: 'EventDetail',
            meta: {
              title: 'event_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/event/Detail.vue'),
          },
        ],
      },
      {
        path: '/user',
        component: RouteWrapper,
        meta: {
          title: 'user',
          icon: 'person',
        },
        redirect: '/user/index',
        children: [
          {
            path: '/user/index',
            name: 'UserIndex',
            meta: {
              title: 'user_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/user/Index.vue'),
          },
          {
            path: '/user/create',
            name: 'UserCreate',
            meta: {
              title: 'user_create',
              icon: 'add',
            },
            component: () => import('@/views/user/Create.vue'),
          },
          {
            path: '/user/detail/:user_id',
            name: 'UserDetail',
            meta: {
              title: 'user_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/user/Detail.vue'),
          },
        ],
      },
    ],
  },
]
