import Axios from 'axios'
import router from '@/router/'
import store from '@/store/'

const axios = Axios.create()
axios.interceptors.request.use((request) => {
  const token = store.getters.getLoginToken
  if (token !== null) {
    request.headers = {
      'Access-Token': 'Bearer ' + token,
    }
  }
  return request
})

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (!error.config.ignoresError) {
      if (
        'response' in error &&
        'status' in error.response &&
        error.response.status == 401 &&
        !error.config.ignores401
      ) {
        router.push({
          path: '/auth/login',
          query: { backuri: window.location.pathname + window.location.search },
        })
      } else if (
        'response' in error &&
        'status' in error.response &&
        error.response.status == 403 &&
        !error.config.ignores403
      ) {
        router.push({ path: '/403' })
      } else if (
        'response' in error &&
        'status' in error.response &&
        error.response.status == 404 &&
        !error.config.ignores404
      ) {
        router.push({ path: '/404' })
      } else if (
        'response' in error &&
        'status' in error.response &&
        error.response.status == 500 &&
        !error.config.ignores500
      ) {
        router.push({ path: '/500' })
      } else {
        alert(
          'Could not process your request. Please try again later.\n処理が正しく行えませんでした。時間をおいてやり直してください。',
        )
      }
    }
    if (error.response.errors && !error.config.ignoresErrorMessage) {
      alert(Object.values(error.response.errors).join('\n'))
    }
    return error.response
  },
)
export default axios
