import Vue from 'vue'
import Router from 'vue-router'
import { publicRoute, protectedRoute } from './config'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '@/store/index.js'

const routes = publicRoute.concat(protectedRoute)

Vue.use(Router)
const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  routes: routes,
})
// router gards
router.beforeEach((to, from, next) => {
  NProgress.start()
  //auth route is authenticated
  if (
    (store.state.auth.login.token && store.state.auth.login.expire > new Date().getTime()) ||
    to.matched.every((page) => {
      for (const i in publicRoute) {
        if (publicRoute[i].path === page.path || publicRoute[i].redirect === page.path) {
          return true
        }
      }
      return false
    })
  ) {
    next()
  } else {
    next({ path: '/auth/login', query: { backuri: to.fullPath } })
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
